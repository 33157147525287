import { DataProvider } from "./context/DataProvider";
import CustomBarChart from "./Graphs/BarChart";
import CustomLineChart from "./Graphs/LineChart";
import valueFormatter from "./utils/valueFormatter";

const App = () => {
  return (
    <>
      <DataProvider apiConfig={{ range: "Översikt!A21:M31" }}>
        <CustomBarChart unit="%" />
      </DataProvider>
      <DataProvider
        exclude={["Totalt"]}
        apiConfig={{ range: "Översikt!A61:M72" }}
      >
        <CustomBarChart unit="kr" />
      </DataProvider>
      <DataProvider
        exclude={["Totalt"]}
        apiConfig={{ range: "Översikt!A99:M109" }}
      >
        <CustomBarChart unit="kr" />
      </DataProvider>
      <DataProvider apiConfig={{ range: "Översikt!A135:M138" }}>
        <CustomLineChart unit="kr" />
      </DataProvider>
      <DataProvider
        title="Intjänad krona per åskådare"
        apiConfig={{ range: "Matchdag!A19:M21" }}
      >
        <CustomLineChart unit="kr" />
      </DataProvider>
      <DataProvider
        title="Storlek procentuellt matchdagsintäkter"
        apiConfig={{ range: "Matchdag!A48:M50" }}
      >
        <CustomLineChart unit="%" />
      </DataProvider>
      <DataProvider
        title="Vinst av marknadsintäkter"
        apiConfig={{ range: "Marknad!A19:M21" }}
      >
        <CustomLineChart unit="%" />
      </DataProvider>
      <DataProvider
        title="Storlek procentuellt marknadsintäkter"
        apiConfig={{ range: "Marknad!A48:M50" }}
      >
        <CustomLineChart unit="%" />
      </DataProvider>
      <DataProvider
        title="Resultat exkl. spelaraffärer"
        apiConfig={{ range: "Spelarförsäljning!A19:M21" }}
      >
        <CustomLineChart unit="kr" />
      </DataProvider>
      <DataProvider
        title="Storlek procentuellt intäkter ifrån spelaraffärer"
        apiConfig={{ range: "Spelarförsäljning!A48:M50" }}
      >
        <CustomLineChart unit="%" />
      </DataProvider>
      <DataProvider
        title="SEF och dylikt"
        apiConfig={{ range: "SEF och dylikt!A19:M70" }}
      >
        <CustomLineChart
          yAxis={[
            {
              id: "primary",
              position: "left",
              label: "Tabellplacering",
              min: 1,
              max: 16,
              reverse: true,
            },
            {
              id: "secondary",
              position: "right",
              label: "Storlek procentuellt intäkter ifrån SEF och dylikt",
              min: 0,
              valueFormatter: (v) =>
                new Intl.NumberFormat("sv-SE").format(v) + "%",
            }, // Lägg till en sekundär axel
          ]}
          customData={{
            "Storlek procentuellt intäkter ifrån SEF och dylikt": {
              yAxisKey: "secondary",
              valueFormatter: (val: number | null) => valueFormatter(val, "%"),
            },
            "SEF-tabell Andel": {
              yAxisKey: "secondary",
              valueFormatter: (val: number | null) => valueFormatter(val, "%"),
            },
            "SEF-tabell Rullande": {
              yAxisKey: "primary",
              valueFormatter: (val: number | null) => valueFormatter(val),
            },
          }}
          leftAxis="primary"
          rightAxis="secondary"
          unit="%"
        />
      </DataProvider>

      <DataProvider
        title="Fotboll (inkl. internationella cuper)"
        apiConfig={{ range: "Fotboll (inkl. internationella cuper)!A19:M78" }}
      >
        <CustomLineChart
          yAxis={[
            {
              id: "primary",
              position: "left",
              label: "Tabellplacering",
              min: 1,
              max: 18,
              reverse: true,
            },
            {
              id: "secondary",
              position: "right",
              label: "Poäng",
              min: 0,
            }, // Lägg till en sekundär axel
          ]}
          customData={{
            "Tabellplacering över tid": {
              yAxisKey: "primary",
              valueFormatter: (val: number | null) => valueFormatter(val),
            },
            "Placering för U19-laget": {
              yAxisKey: "primary",
              valueFormatter: (val: number | null) => valueFormatter(val),
            },
            "Poäng för herrlaget i Allsvenskan": {
              yAxisKey: "secondary",
              valueFormatter: (val: number | null) => valueFormatter(val),
            },
          }}
          leftAxis="primary"
          rightAxis="secondary"
          unit="%"
        />
      </DataProvider>
      <DataProvider
        apiConfig={{ range: "Fotboll (inkl. internationella cuper)!A107:M110" }}
      >
        <CustomLineChart unit="%" />
      </DataProvider>
      <DataProvider
        title="Digitalt"
        apiConfig={{ range: "Medlemmar och supportrar!A19:M50" }}
      >
        <CustomLineChart
          customData={{
            "Sökvolym Totalt": {
              yAxisKey: "primary",
              valueFormatter: (val: number | null) => valueFormatter(val),
            },
            "Sökvolym Sverige": {
              yAxisKey: "primary",
              valueFormatter: (val: number | null) => valueFormatter(val),
            },
            "Antal följare Instagram": {
              yAxisKey: "primary",
              valueFormatter: (val: number | null) => valueFormatter(val),
            },
          }}
        />
      </DataProvider>
      <DataProvider
        title="Medlemmar"
        apiConfig={{ range: "Medlemmar och supportrar!A70:M131" }}
      >
        <CustomLineChart
          yAxis={[
            {
              id: "primary",
              position: "left",
              label: "Antal",
            },
            {
              id: "secondary",
              position: "right",
              label: "Motioner",
              min: 0,
            }, // Lägg till en sekundär axel
          ]}
          customData={{
            "Antal medlemmar i föreningen": {
              yAxisKey: "primary",
              valueFormatter: (val: number | null) => valueFormatter(val),
            },
            "Röstberättigade besökare på årsmötet": {
              yAxisKey: "primary",
              valueFormatter: (val: number | null) => valueFormatter(val),
            },
            "Motioner på årsmötet": {
              yAxisKey: "secondary",
              valueFormatter: (val: number | null) => valueFormatter(val),
            },
          }}
          leftAxis="primary"
          rightAxis="secondary"
        />
      </DataProvider>
      <DataProvider
        apiConfig={{ range: "Styrelse, valberedning och organisation!A22:N25" }}
      >
        <CustomBarChart />
      </DataProvider>
      <DataProvider
        apiConfig={{ range: "Styrelse, valberedning och organisation!A51:M54" }}
      >
        <CustomLineChart
          yAxis={[
            {
              id: "primary",
              position: "left",
              label: "Antal",
              min: 1,
              max: 10,
            },
          ]}
        />
      </DataProvider>
      <DataProvider
        apiConfig={{ range: "Styrelse, valberedning och organisation!A86:M89" }}
      >
        <CustomLineChart
          yAxis={[
            {
              id: "primary",
              position: "left",
              label: "Antal",
              min: 0,
              max: 80,
            },
          ]}
        />
      </DataProvider>
      {/* <DataProvider
        localFile="Ekonomi.A49:M58.json"
        apiConfig={{
          range: "Ekonomi - sammanställning!A49:M58",
        }}
      >
        <Incomes />
      </DataProvider>
      <Costs />
      <Members />
      <Result />
      <Audience />
      <Market />
      <Organization />
      <IncomeTrend />
      <MembersTrend />
      <PositionU19 />
      <Position />
      <Search />
      <Instagram /> */}
    </>
  );
};

export default App;
