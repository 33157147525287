import { BarChart } from "@mui/x-charts/BarChart";
import { useDataContext } from "../context/DataProvider";
import { addLabels } from "../utils/addLabels";
import colors from "../styling/colors";
import getLabels from "../utils/getLabels";

const CustomBarChart = ({ unit }: { unit?: string }) => {
  const { data, loading, setFetchSource } = useDataContext();
  setFetchSource("api");

  if (loading && data.length === 0) return <p>Laddar data...</p>;

  const labels = getLabels(data, unit);
  return (
    <>
      <h2>{data[-1]["title"]}</h2>
      <h3>{data[-1]["Förklaring"]}</h3>
      <BarChart
        colors={colors}
        dataset={data}
        series={addLabels(labels)}
        xAxis={[{ scaleType: "band", dataKey: "year" }]}
        yAxis={[{ valueFormatter: (v) => new Intl.NumberFormat('sv-SE').format(v) + (unit ?? ''), id: "primary" }]}

        
        
        height={500}
        margin={{ top: 100 }}
        leftAxis="primary"
        slotProps={{
          legend: {
            direction: "row",
            position: { vertical: "top", horizontal: "middle" },
          },
        }}
      />
    </>
  );
};

export default CustomBarChart;
