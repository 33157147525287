import { LineChart } from "@mui/x-charts/LineChart";
import { useDataContext } from "../context/DataProvider";
import { addLabels } from "../utils/addLabels";
import colors from "../styling/colors";
import getLabels from "../utils/getLabels";
import {
  AxisConfig,
  ChartsYAxisProps,
  MakeOptional,
  ScaleName,
} from "@mui/x-charts/internals";

const CustomLineChart = ({
  unit,
  yAxis,
  customData,
  leftAxis,
  rightAxis,
}: {
  unit?: string;
  yAxis?: MakeOptional<AxisConfig<ScaleName, any, ChartsYAxisProps>, "id">[];
  customData?: {
    [key: string]: {
      yAxisKey: string;
      valueFormatter: (val: number | null) => string;
    };
  };
  leftAxis?: string;
  rightAxis?: string;
}) => {
  const { data, loading, setFetchSource } = useDataContext();
  setFetchSource("api");

  if (loading && data.length === 0) return <p>Laddar data...</p>;

  const labels = getLabels(data, unit, true, customData);
  return (
    <>
      <h2>{data[-1]["title"]}</h2>
      <h3>{data[-1]["Förklaring"]}</h3>
      <LineChart
        colors={colors}
        dataset={data}
        series={addLabels(labels)}
        xAxis={[{ scaleType: "band", dataKey: "year" }]}
        yAxis={
          yAxis ?? [
            {
              id: "primary",
              valueFormatter: (v) =>
                new Intl.NumberFormat("sv-SE").format(v) + (unit ?? ""),
            },
          ]
        }
        leftAxis={leftAxis ?? "primary"}
        rightAxis={rightAxis}
        height={500}
        margin={{ top: 100 }}
        slotProps={{
          legend: {
            direction: "row",
            position: { vertical: "top", horizontal: "middle" },
          },
        }}
      />
    </>
  );
};

export default CustomLineChart;
